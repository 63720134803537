import React, {useState,useEffect} from "react"

let firstSize = []
let firstAreas = {}

const calculateAreas = (width,height) => {
  let newAreas = {}
  for (var area in firstAreas) {
    if (firstAreas.hasOwnProperty(area)) {
      newAreas[area] = {
        points: firstAreas[area].points.map((pt,i) => (i % 2 === 0) ? (pt/firstSize[0])*width : (pt/firstSize[1])*height).map(n => n.toFixed(3)),
      }
      if(firstAreas[area].href)
        newAreas[area].href = firstAreas[area].href
    }
  }
  return newAreas
}

const renderAreas = (areas) => {
  console.log(areas)
  let allAreas = []
  let count = 0;
  for (var area in areas) {
    if (areas.hasOwnProperty(area)) {
      allAreas.push(<area key={count++} name={area} shape="poly" coords={areas[area].points} href={areas[area].href} alt={""}></area>)
    }
  }
  return allAreas
}
export default function ImageMap({image,imageareas,name,width,height}){
  let [areas,setAreas] = useState(imageareas)
  if(!firstSize.length)
    firstSize = [width,height]

  if(Object.keys(firstAreas).length===0)
    firstAreas = imageareas

  useEffect((e)=>{
    setAreas(calculateAreas(width,height))
    // console.log("calculated areas",areas)
    // calculateAreas(width,height)
  },[width,height])
  return <div id={`${name}-imagemap`} className="custom-imagemap">
    <map name={name} id={name}>
    {renderAreas(areas)}
    </map>
    <img useMap={`#${name}`} width={width} height={height} src={image} alt={name} />
  </div>
}
