import React, {useEffect, useState} from "react"
import { graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import ImageMap from "../components/imagemap"

const offsets = {
  "vangogh_match": [76.4,76.2,9.3,3],
  "vangogh_knife": [82.8,28.9,14.7],
  "vangogh_matchbook": [75.3,33.7,12.1],
  "vangogh_pipe": [21.7,67,19,2],
  "vangogh_shallot": [57.4,39.1,9.4,4],
  "vangogh_pear": [11.6,57.3,16.2],
  "vangogh_candlestick": [66.3,-0.2,19.4],
  "vangogh_onion2": [20.8,24.8,24.3,3],
  "vangogh_onion1": [7.5,11.4,18.9],
  "vangogh_onion3": [34.2,19.3,22.3,2],
  "vangogh_postcard": [51,68.5,33.45],
  "vangogh_winebottle": [0,44.6,12.4],
  "vangogh_teapot": [26.9,0,37.2],
  "vangogh_book": [51,35,41.1,2],
  "vangogh_plate": [19.8,34.9,38.19],
}

let objectElements = [];
let wiggleTimeout;
const wiggleObjects = () => {
  if(objectElements.length === 0){
    objectElements = document.getElementsByClassName("vangogh-object-wrapper")
  }
  let object = objectElements[Math.floor(Math.random()*objectElements.length)]
  object.classList.remove("autowiggle")
  object.classList.add("autowiggle")
  wiggleTimeout = setTimeout(()=>{
    object.classList.remove("autowiggle")
  },500)
  wiggleTimeout = setTimeout(wiggleObjects,500+Math.random()*500)
}

const objStyle = (name) => {
  return {
    left: offsets[name][0]+"%",
    top: offsets[name][1]+"%",
    width: offsets[name][2]+"%",
    zIndex: offsets[name][3] ? offsets[name][3] : 1
  }
}
const vanGoghObjects = (objects) => {
  let placedObjects = objects.map( ({node},i) => {
    console.log(node.name)
    return (<div key={i} className={`vangogh-object-wrapper vangogh-object-wrapper-${node.name}`} data-name={node.name} style={objStyle(node.name)}>
    <GatsbyImage loading={"lazy"} image={getImage(node.childImageSharp.gatsbyImageData)} alt={""}/>
    </div>)
  })
  return <div id="vangogh-objects">{placedObjects}</div>
}

const areas = {
  "match": {href: "/vangogh/rainbow", points: [2593,1797,2484,1805,2324,1780,2400,1759,2534,1771,2665,1780]},
  "postcard": {href: "/vangogh/board-book", points: [1566,1937,1733,1778,1874,1598,2160,1670,2544,1749,2250,2142,2022,2091,1765,2025]},
  "pipe": {href: "/vangogh/rome", points: [1076,1590,1161,1566,1227,1686,1206,1773,1132,1797,1023,1771,925,1728,830,1691,835,1654,936,1667,1073,1710]},
  "pear": {href: "/vangogh/fortune-teller", points: [843,1630,766,1598,716,1498,686,1479,684,1370,620,1344,628,1405,517,1452,461,1490,390,1498,345,1561,403,1664,477,1810,655,1800,771,1792,814,1736]},
  "winebottle": {href: "/vangogh/untitled-flip-book", points: [374,2279,355,1619,228,1407,196,1285,188,1058,146,1002,37,1018,11,1113,34,1230,3,1394,3,2285]},
  "onion1": {href: "/vangogh/vangogh-letter-1", points: [613,1362,605,1261,663,1153,724,1073,660,975,599,941,705,978,888,983,896,967,840,917,666,903,772,861,806,792,780,782,750,835,663,856,676,702,599,609,562,535,557,429,536,429,533,498,430,281,501,482,466,559,445,519,429,557,345,493,252,318,252,350,323,517,429,607,445,671,374,636,254,671,363,660,480,747,488,838,458,928,331,999,305,1068,310,1137,345,1198,408,1248,453,1264,466,1344,549,1381]},
  "book": {href: "/vangogh/carousel", points: [1601,1532,2131,1649,2475,1108,2441,867,2141,856,1990,808,1855,983,1937,1089,2033,1232,1988,1349,1850,1349,1784,1275,1781,1182,1746,1153,1598,1328,1548,1407]},
  "plate": {href: "/vangogh/rainbow", points: [1580,1336,1349,1479,1034,1540,795,1511,692,1466,689,1365,615,1320,612,1246,726,1081,830,989,951,1023,999,1087,959,1272,983,1376,1036,1407,1092,1455,1352,1394,1365,1243,1484,1137,1513,1073,1619,1007,1662,914,1683,928,1675,1050,1699,1002,1715,946,1746,1031,1723,1150,1656,1262]},
  "plate2": {href: "/vangogh/fortune-teller-alt", points: [1468,824,1619,861,1606,938,1516,1044,1513,946,1484,885]},
  "matchbook": {href: "/vangogh/carousel", points: [2470,965,2539,991,2653,930,2650,861,2406,795,2300,853,2451,872]},
  "candlestick": {href: "/vangogh/fortune-teller", points: [2025,644,2102,686,2086,758,2322,835,2409,792,2465,806,2600,665,2555,618,2571,546,2425,466,2378,451,2391,427,2319,400,2293,138,2256,125,2229,13,2197,13,2200,138,2202,342,2200,416,2141,448,2160,512]},
  "teapot": {href: "/vangogh/landscape-foldout", points: [1929,623,1903,496,1868,437,1892,284,1866,220,1794,204,1749,220,1678,11,1540,5,1609,82,1452,66,1317,93,1195,130,1145,162,1137,82,1208,0,1095,0,1049,98,1057,241,1097,329,1018,371,898,302,819,366,938,493,906,615]},
  "knife": {href: "/vangogh/fortune-teller-alt", points: [2581,827,2730,769,2754,734,2799,739,2891,697,2902,676,2963,726,2920,766,2852,803,2804,822,2658,893,2653,851]},
  "shallot": {href: "/vangogh/treatise-of-spirits", points: [1749,1140,1744,1050,1762,1002,1792,1065,1794,981,1818,1010,1834,914,1855,959,1860,1055,1937,1100,2025,1235,1985,1333,1855,1341,1792,1275,1792,1182]},
  "onion2": {href: "/vangogh/vangogh-letter-2", points: [1346,1381,1362,1105,1264,1015,1163,1002,1102,1034,1055,997,933,798,893,795,859,782,848,636,803,586,787,607,819,676,827,761,800,742,816,845,723,888,835,888,893,917,917,952,991,1012,965,1026,1007,1095,967,1272,991,1373,1097,1447]},
  "onion3": {href: "/vangogh/vangogh-letter-3", points: [1034,938,1068,827,1142,779,1208,776,1391,625,1460,665,1564,623,1688,678,1712,792,1715,959,1686,1044,1678,938,1670,758,1656,898,1622,1007,1516,1065,1606,954,1627,848,1619,766,1545,755,1497,737,1426,824,1466,816,1505,954,1508,1036,1476,1132,1370,1222,1373,1103,1275,1005,1158,997,1097,1020,1052,986]},
}

var wrapperElement;
export default function VanGoghPage({data}){
  let image = getImage(data.file.childImageSharp)
  let [imageSize,setImageSize] = useState([3000,2290])
  useEffect((e) =>{
    if(typeof window != "undefined"){
      wiggleObjects()
      var areas = document.getElementsByTagName("area");
      for (var i = 0; i < areas.length; i++) {
        areas[i].onmouseenter = function(e){
          console.log(e.target.getAttribute("name"))
          var el = document.querySelector(`[data-name='vangogh_${e.target.getAttribute("name")}']`);
          if(el)
          el.classList.add("wiggle")
        }
        areas[i].onmouseleave = function(e){
          var el = document.querySelector(`[data-name='vangogh_${e.target.getAttribute("name")}']`);
          if(el)
          el.classList.remove("wiggle")
        }
      }
      setTimeout(function(){
        wrapperElement = document.getElementById("vangogh-page")
        if(window.innerWidth<480){
          wrapperElement.style.height = window.innerHeight+"px";
        }
        setImageSize([wrapperElement.offsetWidth,wrapperElement.offsetHeight])
        wrapperElement.classList.add("ready")
      },500)
      window.onresize = function(e){
        setImageSize([wrapperElement.offsetWidth,wrapperElement.offsetHeight])
      }
    }
    return () => {
      if(wiggleTimeout > -1)
        clearTimeout(wiggleTimeout)
    }
  },[])
  return(
    <div id="vangogh-page-wrapper">
      <div id="vangogh-page">
        <div id="page-img-wrapper">
          <GatsbyImage alt="" loading={"eager"} image={image}/>
        </div>
        {<ImageMap imageareas={areas} name="vangogh" width={imageSize[0]} height={imageSize[1]} image={image.images.fallback.src}/>}
        {vanGoghObjects(data.objects.edges)}
      </div>
    </div>
  )
}

export const query = graphql`
query GetVanGogh {
  file(relativePath: {eq: "vangogh-big.jpg"}) {
    publicURL
    childImageSharp {
      gatsbyImageData
    }
  }
  objects: allFile(filter: {relativeDirectory: {eq: "vangoghpics"}}) {
    edges {
      node {
        id
        name
        childImageSharp {
          gatsbyImageData
        }
      }
    }
  }
}
`
